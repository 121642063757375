import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_readOnlyGrid = _resolveComponent("readOnlyGrid")

  return (_openBlock(), _createBlock(_component_readOnlyGrid, {
    gridColumns: _ctx.gridColumns,
    gridItems: _ctx.gridItems,
    isLoading: _ctx.isLoading
  }, null, 8, ["gridColumns", "gridItems", "isLoading"]))
}