
  import { defineComponent } from "vue";
  import { GridColumnProps } from "@progress/kendo-vue-grid";
  // @ts-expect-error 
  import { readOnlyGrid, renderCellWithLink } from "hestia";
  import { QuoteVM } from "@/models";
import { RegistrationService } from "@/services";

export default defineComponent({
  name: "DetailGridQuotes",
  components: {
    readOnlyGrid,
  },
  inject: ["api", "userAuth"],
  props: {
    registrationNumber: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      gridItems: [] as QuoteVM[],
      gridColumns: [
        { 
          field: "quoteId", 
          title: "Quote ID", 
          filter: "text", 
          cell: renderCellWithLink(this.getQuoteLink, "_blank")
        },
        {
          field: "rfqId",
          title: "RFQ ID",
          filter: "text",
          cell: renderCellWithLink(this.getRfqLink, "_blank")
        },
        { field: "applicationName", title: "Application", filter: "text" },
        { field: "quantity", title: "Quantity", filter: "numeric", format: "{0:##,#}" },
        { field: "distributorCost", title: "Disti Cost", filter: "numeric", format: "{0:$ 0.0000}" },
        { field: "distributorResale", title: "Resale", filter: "numeric", format: "{0:$ 0.0000}" },
        {
          field: "startOfProduction",
          title: "Start of Production",
          filter: "date",
          format: "{0:d-MMM-y}",
        },
      ] as GridColumnProps[],
      apiClient: new RegistrationService(this.api)
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      this.isLoading = true;
      try {
        if(this.registrationNumber)
          this.gridItems = await this.apiClient.getQuotes(this.registrationNumber);
      } finally {
        this.isLoading = false;
      }
    },
    getQuoteLink(dataItem: QuoteVM): string {
      if(!this.userAuth.isAllegro || dataItem.quoteId === null) return "";
      return `${process.env.VUE_APP_PHOENIX_CLIENT_URL}/phoenix.aspx?QuoteID=${dataItem.quoteId}`;
    },
    getRfqLink(dataItem: QuoteVM): string {
      if(!this.userAuth.isAllegro) return "";
      return `${process.env.VUE_APP_PHOENIX_CLIENT_URL}/phoenix.aspx?RFQID=${dataItem.rfqId}`;
    },
  }
});
