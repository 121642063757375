
import { defineComponent, PropType } from "vue";
import { RegistrationDetailVM, RegistrationNotePM, RegistrationNoteVM } from "@/models";
import { RegistrationNoteService } from "@/services";
import { RegistrationStatus, RenewalSubmissionStatuses } from "@/enums";
// @ts-expect-error
import { toggleButton } from "hestia";

export default defineComponent({
  name: "DetailExternalComments",
  inject: ["api", "userAuth"],
  components: {
    toggleButton,
  },
  props: {
    registration: {
      type: Object as PropType<RegistrationDetailVM>,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isEditing: false,
      externalNote: new RegistrationNotePM(),
      existingNotes: [] as RegistrationNoteVM[],
      apiClient: new RegistrationNoteService(this.api),
      isNotesHistoryExpanded: false,
    };
  },
  methods: {
    async getExternalComments() {
      if (!this.registration) {
        return;
      }
      this.isLoading = true;
      try {
        this.existingNotes = await this.apiClient.getRegistrationExternalNote(
          this.registration.id,
          this.registration.version
        );
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
    modifyNote() {
      this.isEditing = true;
      // @ts-expect-error
      this.$nextTick(() => this.$refs.externalComments.focus());
    },

    cancelEditing() {
      this.isEditing = false;
      this.externalNote.note = "";
    },
    async createNote() {
      this.isLoading = true;
      this.externalNote.toExternalCommentPM(this.registration.id, this.registration.version);
      try {
        const savedNote = await this.apiClient.createExternalNote(this.externalNote);
        this.existingNotes.unshift(savedNote);
        this.externalNote = new RegistrationNotePM();
        this.isEditing = false;
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    notesExceptFirst(): RegistrationNoteVM[] {
      return this.existingNotes.slice(1);
    },
    isExternalCommentVisible(): boolean {
      return (
        this.isRegActive || 
        RenewalSubmissionStatuses.includes(this.registration.statusId) || 
        this.isRegPreexpired
      );
    },
    isRegActive(): boolean {
      return this.registration.statusId === RegistrationStatus.ACTIVE;
    },
    isRegPreexpired(): boolean {
      return this.registration.statusId === RegistrationStatus.PREEXP;
    },
    anyExistingNotes(): boolean {
      return Boolean(this.existingNotes?.length);
    },
  },
  watch: {
    registration: {
      handler: function(newValue: RegistrationDetailVM) {
        if (!newValue.id) {
          return;
        }
        this.getExternalComments();
      },
      immediate: true,
    },
  },
});
