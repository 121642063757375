
import { defineComponent, PropType } from "vue";
import { RegistrationDocumentVM } from "@/models";
import { downloadFile, FileExtensionIcons } from "@/helpers";
// @ts-expect-error 
import { loading, modalCard } from "aml";

export default defineComponent({
  name: "DetailSupportingDocs",
  components: {
    loading,
    modalCard
  },
  props: {
    documents: {
      type: Array as PropType<RegistrationDocumentVM[]>,
      required: false,
      default: [],
    },
    areFilesArchived: Boolean
  },
  inject: ['api'],
  data() {
    return {
      loadingReferences: [] as string[],
      isArchivedDialogVisible: false,
    }
  },
  methods: {
    getFileIcon(doc: RegistrationDocumentVM) {
      if (this.areFilesArchived) return "fas fa-archive has-text-danger";

      const docNameArr = doc.documentName?.split(".");
      if (docNameArr) {
        return FileExtensionIcons[docNameArr[docNameArr.length - 1]] || "fas fa-file";
      }
    },
    async downloadFile(doc: RegistrationDocumentVM) {
      if (this.checkArchived()) return;

      let response;
      try {
        this.loadingReferences.push(doc.referenceName!);
        response = await this.api.getFileResponse(doc.documentName, doc.referenceName);
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.loadingReferences = this.loadingReferences.filter(d => d !== doc.referenceName);
      }

      const blob = await response.blob();
      downloadFile(blob, doc.documentName!);
    },
    checkArchived() {
      return this.isArchivedDialogVisible = this.areFilesArchived;
    }
  },
  computed: {
    isLoading(): Record<string, boolean> {
      const loadingDocs = {} as Record<string, boolean>;
      this.loadingReferences.forEach(d => loadingDocs[d] = true);
      return loadingDocs;
    }
  }
});
