
import { defineComponent } from "vue";
import { RegistrationDetailVM } from "@/models";
import { RegistrationStatus, SubmissionStatuses } from "@/enums";

import DetailFields from "./detail-fields.vue";
import DetailActions from "./detail-actions.vue";
import DetailSupportingDocs from "../detail-supporting-docs.vue";
import DetailGridHistory from "./detail-grid-history.vue";
import DetailGridQuotes from "./detail-grid-quotes.vue";
import DetailInternalComments from "./detail-internal-comments.vue";
import DetailExternalComments from "./detail-external-comments.vue";
import DetailGridDebitAuths from "./detail-grid-debit-auths.vue";
import { RegistrationReviewManager } from "@/components";
import { RegistrationService } from "@/services";

export default defineComponent({
  components: {
    DetailActions,
    DetailFields,
    DetailSupportingDocs,
    DetailGridHistory,
    DetailGridQuotes,
    DetailGridDebitAuths,
    RegistrationReviewManager,
    DetailInternalComments,
    DetailExternalComments
  },
  name: "Detail",
  inject: ["api", "userAuth"],
  data() {
    return {
      registration: {} as RegistrationDetailVM,
    };
  },
  computed: {
    pageTitle(): string {
      return this.$route.meta.title as string;
    },
    registrationNumber(): string {
      return this.$route.params.registrationNumber as string;
    },
    registrationApi(): RegistrationService {
      return new RegistrationService(this.api);
    },
    isRejected(): boolean {
      return this.registration?.statusId === RegistrationStatus.REJECTED;
    },
    isSubmitted(): boolean {
      return SubmissionStatuses.indexOf(this.registration?.statusId) > -1;
    },
    isArchived(): boolean {
      return this.registration?.statusId === RegistrationStatus.ARCHIVED;
    },
    areQuotesAndDebitAuthsVisible(): boolean {
      return !(this.isRejected || this.isSubmitted);
    }
  },
  methods: {
    async loadDetail() {
      this.$hardLoading();
      try {
        this.registration = await this.registrationApi.getRegistrationDetails(
          this.registrationNumber
        );
      } catch (error) {
        this.$errorPage(error);
      } finally {
        this.$doneLoading();
      }
    },
  },
  beforeMount() {
    this.loadDetail();
  },
});
