
import { defineComponent, PropType } from "vue";
import { RegistrationDetailVM } from "@/models";
import { Tooltip } from "@progress/kendo-popups-vue-wrapper";
import { RegistrationStatus } from "@/enums";
import { FormatHelpers } from "@/helpers";


export default defineComponent({
  name: "DetailFields",
  components: {
    Tooltip
  },
  props: {
    registrationDetail: {
      type: Object as PropType<RegistrationDetailVM | null>,
    },
  },
  methods: {
    ...FormatHelpers
  },
  computed: {
    tooltipContent(): string {
      const statusesToShowCostFromDb = [ RegistrationStatus.ACTIVE, RegistrationStatus.PREEXP, RegistrationStatus.EXPIRED ];
      const showCostFromDb = this.registrationDetail && statusesToShowCostFromDb.includes(this.registrationDetail.statusId);
      return `<p class="cost-tooltip">${showCostFromDb ? "Cost valid on the day of the final approval (of new or renewed registration)." : "Current valid cost."}</p>`;
    },
    expirationDate(): string {
      const isDraft = this.registrationDetail?.statusId === RegistrationStatus.DRAFT;
      const dateString = this.formatDate(this.registrationDetail?.expirationDate);

      return (isDraft || !dateString) ? "1 year from approval" : dateString;
    }
  },
});
