
  import { defineComponent } from "vue";
  import { GridColumnProps } from "@progress/kendo-vue-grid";
  // @ts-expect-error 
  import { readOnlyGrid, renderCellWithLink } from "hestia";
  import { RegistrationService } from "@/services";
  import { DebitAuthorizationVM } from "@/models";
  
  export default defineComponent({
    name: "DetailGridDebitAuths",
    components: {
      readOnlyGrid,
    },
    inject: ["api", "userAuth"],
    props: {
      registrationNumber: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        isLoading: false,
        gridItems: [] as any[],
        gridColumns: [
          { 
            field: "debitAuthorizationId", 
            title: "Debit ID", 
            filter: "text", 
            cell: renderCellWithLink(this.getDebitAuthorizationLink, "_blank") 
          },
          {
            field: "quoteId",
            title: "Quote ID",
            filter: "text",
            cell: renderCellWithLink(this.getQuoteLink, "_blank")
          },
          { field: "authorizedQuantity", title: "Authorized Qty", filter: "numeric", format: "{0:##,#}" },
          { field: "claimedQuantity", title: "Consumed Qty", filter: "numeric", format: "{0:##,#}" },
          { field: "debitAuthorizationStatus", title: "Status", filter: "text" },
          { field: "distributorCost", title: "Disti Cost", filter: "numeric", format: "{0:$ 0.0000}" },
          { field: "distributorResale", title: "Resale", filter: "numeric", format: "{0:$ 0.0000}" },
          { field: "expirationDate", title: "Expiration", filter: "date", format: "{0:d-MMM-y}" },
        ] as GridColumnProps[],
        apiClient: new RegistrationService(this.api)
      };
    },
    mounted() {
      this.loadItems();
    },
    methods: {
      async loadItems() {
        this.isLoading = true;
        try {
          if(this.registrationNumber)
            this.gridItems = await this.apiClient.getDebitAuthorizations(this.registrationNumber);
        } finally {
          this.isLoading = false;
        }
      },
      getQuoteLink(dataItem: DebitAuthorizationVM): string {
        if(!this.userAuth.isAllegro || dataItem.quoteId === null) return "";
        return `${process.env.VUE_APP_PHOENIX_CLIENT_URL}/phoenix.aspx?QuoteID=${dataItem.quoteId}`;
      },
      getDebitAuthorizationLink(dataItem: DebitAuthorizationVM): string {
        if(!this.userAuth.isAllegro) return "";
        return `${process.env.VUE_APP_PHOENIX_CLIENT_URL}/phoenix.aspx?DebitID=${dataItem.debitAuthorizationId}`;
      },
    }
  });
  