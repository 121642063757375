
import { defineComponent, PropType } from "vue";
import { RegistrationDetailVM, RegistrationNotePM, RegistrationNoteVM } from "@/models";
import { RegistrationNoteService } from "@/services";
import { RegistrationStatus, SubmissionStatuses } from "@/enums";

export default defineComponent({
  name: "DetailInternalComments",
  inject: ["api", "userAuth"],
  props: {
    registration: {
      type: Object as PropType<RegistrationDetailVM>,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isEditing: false,
      internalNote: new RegistrationNotePM(),
      existingNotes: [] as RegistrationNoteVM[],
      apiClient: new RegistrationNoteService(this.api),
    };
  },
  methods: {
    async getInternalComments() {
      if (!this.registration) {
        return;
      }
      this.isLoading = true;
      try {
        this.existingNotes = await this.apiClient.getRegistrationInternalNote(
          this.registration.id,
          this.registration.version
        );
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
    modifyNote() {
      this.isEditing = true;
      // @ts-expect-error
      this.$nextTick(() => this.$refs.internalComments.focus());
    },

    cancelEditing() {
      this.isEditing = false;
      this.internalNote.note = "";
    },
    async createNote() {
      this.isLoading = true;
      this.internalNote.toInternalCommentPM(this.registration.id, this.registration.version);
      try {
        const savedNote = await this.apiClient.createInternalNote(this.internalNote);
        this.existingNotes.unshift(savedNote);
        this.internalNote = new RegistrationNotePM();
        this.isEditing = false;
      } catch (error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    isInternalCommentVisible(): boolean {
      return (
        this.userAuth.isAllegro &&
        (SubmissionStatuses.some(s => s === this.registration.statusId) || this.isRegActive) &&
        !(!this.anyExistingNotes && this.isRegActive)
      );
    },
    isRegActive(): boolean {
      return this.registration.statusId === RegistrationStatus.ACTIVE;
    },
    anyExistingNotes(): boolean {
      return Boolean(this.existingNotes?.length);
    },
  },
  watch: {
    registration: {
      handler: function(newValue: RegistrationDetailVM) {
        if (!newValue.id) {
          return;
        }
        this.getInternalComments();
      },
      immediate: true,
    },
  },
});
