
import { defineComponent, PropType } from "vue";
import { GridColumnProps } from "@progress/kendo-vue-grid";
// @ts-expect-error 
import { readOnlyGrid, renderFilterableCellWithDatetime } from "hestia";
import { RegistrationDetailVM, RegistrationHistoryRecordVM } from "@/models";
import { RegistrationService } from "@/services";

export default defineComponent({
  name: "DetailGridHistory",
  components: {
    readOnlyGrid,
  },
  inject: ["api", "userAuth"],
  props: {
    registration: {
      type: Object as PropType<RegistrationDetailVM>,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      gridItems: [] as RegistrationHistoryRecordVM[],
      gridColumns: [
        { field: "what", title: "What", filter: "text" },
        {
          field: "whom",
          title: "Whom",
          filter: "text",
        },
        {
          field: "whenForSearch",
          title: "When",
          filterable: true,
          filter: "date",
          width: 200,
          cell: renderFilterableCellWithDatetime("when", "HH:mm:ss | d-MMM-y")
        },
        {
          field: "completionComment", 
          title: "Review Comments", 
          filter: "text"
        }
      ] as GridColumnProps[],
      apiClient: new RegistrationService(this.api)
    };
  },
  methods: {
    async loadHistoryItems() {
      if(!this.registration) return;
      this.isLoading = true;
      try {
        this.gridItems = await this.apiClient.getRegistrationHistoryRecords(this.registration.id);
      } catch(error) {
        this.$errorModal(error);
      } finally {
        this.isLoading = false;
      }
    }    
  },
  watch: {
    registration: {
      handler: function(newValue: RegistrationDetailVM) {
        if(!newValue.id) {
          return;
        }
        this.loadHistoryItems();
      },
      immediate: true
    }
  }
});
