
import { defineComponent, PropType } from "vue";
import { IncentiveProgramEditPM, RegistrationDetailVM, SubmitRenewalPM } from "@/models";
import { RegistrationStatus, RenewalSubmissionStatuses, SubmissionStatuses } from "@/enums";
import { RegistrationExpireNowDialog, RegistrationRenewalDialog, RegistrationEditIncentiveProgramDialog } from "@/components";
// @ts-expect-error
import { modalDialog } from "aml";
import { RegistrationService } from "@/services";
import { routeNames } from "@/router";

export default defineComponent({
  name: "DetailActions",
  components: {
    modalDialog,
    RegistrationRenewalDialog,
    RegistrationExpireNowDialog,
    RegistrationEditIncentiveProgramDialog
  },
  inject: ["api", "userAuth"],
  emits: ["actionDone", "update:registrationDetail"],
  props: {
    registrationDetail: {
      type: Object as PropType<RegistrationDetailVM>,
      required: true
    },
  },
  data() {
    return {
      actionPromiseFn: null as null | (() => Promise<any>),
      confirmDialogTitle: "",
      isConfirmDialogVisible: false,
      isRenewalDialogVisible: false,
      renewalComments: "",
      isExpireDialogVisible: false,
      isEditIncentiveProgramDialogVisible: false,
      expireNowComments: "",
      incentiveProgramEditModel: new IncentiveProgramEditPM()
    };
  },
  computed: {
    registrationApi(): RegistrationService {
      return new RegistrationService(this.api);
    },
    canModify(): boolean {
      return this.registrationDetail.canModify;
    },
    canCancelRenewal(): boolean {
      return this.canModify && RenewalSubmissionStatuses.includes(this.registrationDetail.statusId);
    },
    canCancelSubmission(): boolean {
      return this.canModify && SubmissionStatuses.includes(this.registrationDetail.statusId);
    },
    isEditIncentiveProgramVisible(): boolean {
      return this.registrationDetail.canModifyRegistrationIncentiveProgram;
    },
    // Don't show edit incentive program button if there are no options and no current program
    noIncentiveProgramOptions(): boolean {
      return this.registrationDetail.incentiveProgramOptions.length === 0 && !this.registrationDetail.incentiveProgramName;
    },
    isReturnToDraftAfterRejectVisible(): boolean {
      return this.canModify && this.registrationDetail.statusId === RegistrationStatus.REJECTED;
    },
    isSubmitRenewalVisible(): boolean {
      return this.canModify && this.registrationDetail.statusId === RegistrationStatus.PREEXP;
    },
    isCreateRfqVisible(): boolean {
      // TODO: implement button functionality when we have RFQ application
      // return this.registrationDetail.statusId === RegistrationStatus.ACTIVE;
      return false;
    },
    isEditDraftVisible(): boolean {
      return this.canModify && this.registrationDetail.statusId === RegistrationStatus.DRAFT;
    },
    isExpireNowVisible(): boolean {
      return this.userAuth.canExpireNow && [RegistrationStatus.ACTIVE, RegistrationStatus.PREEXP].includes(this.registrationDetail.statusId);
    }
  },
  methods: {
    async resolveConfirmDialogAction(proceed: boolean) {
      this.isConfirmDialogVisible = false;
      this.$closeModal(async () => {
        if(proceed) {
          if(!this.actionPromiseFn) return;
          try {
            await this.actionPromiseFn();
            this.$emit("actionDone");
          } catch (error) {
            this.$errorModal(error);
          }
        }
        this.actionPromiseFn = null;
        this.confirmDialogTitle = "";
      });
    },
    async cancelSubmission(): Promise<any> {
      await this.getDetails();
      if (this.canCancelSubmission) {
        return this.registrationApi.cancelSubmission(this.registrationDetail.id, this.registrationDetail.rowVersion);
      } else {
        this.showWarningToast("submission cancellation");
      }
    },
    async cancelRenewal(): Promise<any> {
      await this.getDetails();
      if (this.canCancelRenewal) {
        return this.registrationApi.cancelRenewal(this.registrationDetail.id, this.registrationDetail.rowVersion);
      } else {
        this.showWarningToast("renewal cancellation");
      }
    },
    returnToDraftAfterReject(): Promise<any> {
      return this.registrationApi.returnToDraftAfterReject(this.registrationDetail.id, this.registrationDetail.rowVersion);
    },
    submitRenewal(): Promise<any> {
      return this.registrationApi.submitRenewal(new SubmitRenewalPM(this.registrationDetail.id, this.renewalComments, this.registrationDetail.rowVersion));
    },
    expireRegistration(): Promise<any> {
      return this.registrationApi.expireRegistration(this.registrationDetail.id, this.expireNowComments, this.registrationDetail.rowVersion);
    },
    confirmAction(actionPromiseFn: () => Promise<any>, dialogTitle: string) {
      this.actionPromiseFn = actionPromiseFn;
      this.confirmDialogTitle = dialogTitle;
      this.isConfirmDialogVisible = true;
    },
    async resolveRenewalDialogAction(proceed: boolean) {
      this.isRenewalDialogVisible = false;
      
      this.$closeModal(async () => {
        if(proceed) {
          try {
            await this.submitRenewal();
            this.$emit("actionDone");
          } catch (error) {
            this.$errorModal(error);
          }
        }
      });
    },
    async resolveExpireNowDialogAction(proceed: boolean) {
      this.isExpireDialogVisible = false;

      this.$closeModal(async () => {
        if(proceed) {
          try {
            await this.expireRegistration();
            this.$emit("actionDone");
          } catch (error) {
            this.$errorModal(error);
          }
        }
      })
    },
    async resolveEditIncentiveProgramDialogAction(proceed: boolean) {
      this.isEditIncentiveProgramDialogVisible = false;

      this.$closeModal(async () => {
        if(proceed) {
          try {
            this.incentiveProgramEditModel.registrationId = this.registrationDetail.id;
            this.incentiveProgramEditModel.registrationRowVersion = this.registrationDetail.rowVersion;
            await this.registrationApi.editIncentiveProgram(this.incentiveProgramEditModel);
            this.$emit("actionDone");
          } catch (error) {
            this.$errorModal(error);
          }
        }
      })
    },
    goToEdit() {
      this.$router.push({ name: routeNames.registrationEdit, params: { registrationNumber: this.registrationDetail.registrationNumber }});
    },
    async getDetails() {
      try {
        const registration = await this.registrationApi.getRegistrationDetails(this.registrationDetail.registrationNumber);
        this.$emit("update:registrationDetail", registration);
      } catch (error) {
        this.$errorModal(error);
      } 
    },
    showWarningToast(cancellationType: string) {
      const message = `Registration is in status that doesn't allow ${cancellationType}. Please see updated registration.`
      this.$toast({ message, type: "warning" });
    }
  }
});
